import React, { useEffect, useRef } from "react";
import Seo from "../../../components/Seo/seo";
import Layout from "../../../components/Layout/layout";
import HeroSection from "../../../components/MLPPartTime/HeroSection/heroSection";
import MLPForm from "../../../components/Forms/MLPPartTimeForm";
import ProgramBrief from "../../../components/MLPPartTime/GoTo/goTo";
import NavBar from "../../../components/NavBar/navbar";
import Overview, { ProgramSchedule } from "../../../components/MLPPartTime/Overview/overview";
import Placements from "../../../components/MLPPartTime/Placements/placements";
import Fee from "../../../components/MLPPartTime/Fee/fee";
import Benefits from "../../../components/MLPPartTime/Benefits/benefits";
import AdmissionProcess from "../../../components/MLPPartTime/AdmissionProcess/admissionProcess";
import Trainers from "../../../components/MLPPartTime/Trainers/trainers";
import Curriculum from "../../../components/MLPPartTime/Curriculum/curriculum";
import FAQ from "../../../components/MLPPartTime/FAQS/faq";

import {
  courseSnipptJSON,
  faqSnipptJSON,
  videoSnipptJSON,
  eventSnipptJSON,
} from "../../../components/Seo/csmSeoData";

import "./styles.scss";
import { useState } from "react";
import IvrSection from "../../../components/MLPMainComp/Ivr";
import ContactUsPopup from "../../../components/ContactUsPopup";
import ToolsCovered from "../../../components/MLPPartTime/ToolsCovered";
import Eligibility from "../../../components/MLPPartTime/Placements/Eligibility";
import CareerTrajectory from "../../../components/MLPPartTime/CareerTrajectory";
import AlumniCarousal from "../../../components/MLPMainComp/Placements/AlumniCarousal";
import CourseTracks from "../../../components/MLPPartTime/OtherCourses";
import NewsSection from "../../../components/MLPMainComp/NewsSection/NewsSection";

const MarketingLaunchPadPartTime = (props) => {
  const navBarContent = [
    {
      name: "Overview",
      id: "overview",
    },
    {
      name: "Placements",
      id: "placements",
    },
    // {
    //   name: "Fee",
    //   id: "fee",
    // },
    {
      name: "Benefits",
      id: "benefits",
    },
    {
      name: "Curriculum",
      id: "curriculum",
    },
    {
      name: "Experts",
      id: "trainers",
    },
    {
      name: "Admissions",
      id: "admissions",
    },
    {
      name: "Alumni",
      id: "alumni"
    },
    {
      name: "Apply Now",
      id: "mlp-part-time-from"
    }
    // {
    //   name: "FAQS",
    //   id: "faqs",
    // } 
  ];

  const [isHidden, setIsHidden] = useState(true);

  const [showContactUsPopup, setContactUsPopup] = useState(false);

  const contactUsTimeout = useRef(null);

  useEffect(() => {

    contactUsTimeout.current = setTimeout(() => {
      setContactUsPopup(true);
    }, 40 * 1000);

    function handleScroll() {
      const currentScrollPosition = window.pageYOffset;

      setIsHidden(currentScrollPosition < 1400); // Change 100 to the number of pixels after which you want to hide the element
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);

      if(contactUsTimeout.current)
        clearTimeout(contactUsTimeout.current);
    };
  }, []);

  return (
    <React.Fragment>
      <Seo
        title="India’s First Part-time Digital Marketing Weekend Course With Placement - Kraftshala"
        description="Enroll into India’s best part-time digital marketing course and learn Facebook Ads, Google Ads and Amazon Ads on your weekends. Apply today!"
        url="https://www.kraftshala.com/marketing-launchpad/part-time-digital-marketing-course/"
        courseSnipptJSON={courseSnipptJSON}
        faqSnipptJSON={faqSnipptJSON}
        videoSnipptJSON={videoSnipptJSON}
        eventSnipptJSON={eventSnipptJSON}
      />
      {showContactUsPopup && <ContactUsPopup />}
      <div className="main-csm-parttime-page">
        <Layout formLink="/marketing-launchpad/part-time-digital-marketing-course/">
          <div className="hero-div" id="hero-div">
            <HeroSection urlParams={props.location.search} />
            <MLPForm search={props.location.search} />
          </div>
          <ProgramBrief />
          <NavBar
            content={navBarContent}
            applyLink={`/marketing-launchpad/part-time-digital-marketing-course/${props.location.search}`}
          />

          <div className={isHidden ? "fixed-bottomHidden" : "fixed-bottom"}>
            {!isHidden && (
              <>
                {" "}
                <button
                  className={isHidden ? "fixed-bottomHidden" : "footer-btn"}
                  onClick={() => {
                    document.querySelector("#hero-div").scrollIntoView({
                      behavior: "smooth",
                      block: "end",
                      inline: "nearest",
                    });
                  }}
                >
                      Start my marketing career
                </button>
   
              </>
            )}
          </div>


          <div id="overview" className="section">
            <Overview />
          </div>
          <div className="csm-main-divider"></div>

          <div id="placements" className="section">
            <Placements />
          </div>

          <div id="benefits" className="section">
            <Benefits />
          </div>

          <div id="curriculum" className="section">
            <ProgramSchedule />
          </div>

          <div id="tools-covered" className="section">
            <ToolsCovered />
          </div>

          <div id="program-schedule" className="section">
            <Curriculum />
            {/* <StudentSlider/> */}
          </div>

          <div id="trainers" className="section">
            <Trainers />
          </div>

          <div id="admissions" className="section">
            <AdmissionProcess urlParams={props.location.search} />
          </div>

          <div id="fee" className="section">
            <Fee />
            {/* <FeeSlider /> */}
          </div>

         

          <div id="eligibility" className="section">
            <Eligibility />
          </div>

          <div id="career-trajectory" className="section">
            <CareerTrajectory />
          </div>

          <div id="alumni" className="section">
            <AlumniCarousal />
          </div>

        
          <div id="news-section" className="section">
            <NewsSection />
          </div>

          <div id="other-courses" className="section">
            <CourseTracks />
          </div>
          
          <div id="faq" className="section">
            <FAQ />
          </div>

          <div id="ivr" className="section">
            <IvrSection />
          </div>
            

        </Layout>
      </div>
      {/* <WhatsappWidget link="https://wa.me/message/HHNAOZDIMB4ZE1" /> */}
    </React.Fragment>
  );
};

export default MarketingLaunchPadPartTime;
