export const trainersImages = [
  {
    title: "Marketing Launchpad",
    name: "Digital",
    description: "16 Weeks | Full-Time",
    link:
        "https://www.kraftshala.com/marketing-launchpad/digital-marketing-course/",
  },
  
  {
    title: "Marketing Launchpad",
    name: "Content Writing & Social",
    description: "16 Weeks | Full-Time",
    link:
        "https://www.kraftshala.com/marketing-launchpad/content-writing-and-social-media-marketing-course/",
  },
  {
    title: "Business Leadership",
    name: "PGP in Sales and",
    subTitle: "",
    description: "8 Months | Part-Time",
    link:
        "https://www.kraftshala.com/sales-course/",
  },
  {
    title: "Launchpad - Emerging Talent",
    name: "Digital  Marketing",
    subTitle: "(Hindi & English)",
    description: "16 Weeks | Full-Time",
    link:
        "https://www.kraftshala.com/marketing-launchpad/digital-marketing-course-in-hindi/",
  },
];