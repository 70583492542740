import React from "react";

import "./styles.scss";

function Eligibility() {

  return (
    <React.Fragment>
     
      <div style={{margin: "6rem 0 0"}} className="should-apply-mlp part-time-apply">
        <div className="apply-container-mlp">
          <h2 className="should-apply-header">Who Should Apply?</h2>

          <div className="vl"></div>
          <h3 className="should-description">
            <div className="should-apply-sub-header">
            Part-Time Digital marketing course eligibility
            </div>
            <div className="should-apply-description">
              <ol>
                <li>Working Professional - doesn't matter what field you are in.</li>
                <li>Final-year students from any background who are graduating in 2024.</li>
                <li>No prior work experience is necessary.</li>
              </ol>
            </div>
          </h3>

        </div>
      </div>
    </React.Fragment>
  );
}

export default Eligibility;
