import React from "react";
import "./styles.scss";

/*Card for Student Testemonial*/
function Card5({ name, description, link, title,subTitle }) {
  return (
    <div className="card-5-testimonials">
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21g93m5.png" className="yellowTicket"  loading="lazy" />
      <div className="type2-info">
        <div>
          <div className="name">{name}</div>
          <div className="title">{title}</div>
        </div>
        <div>
          {subTitle && <div className="subTitle">{subTitle}</div>}
          <div className="type2-description1">{description}</div>
          <a className="dbb-program-card-btn" target="_blank" rel="noreferrer" href={link}>
            Explore Program
          </a>
        </div>
      </div>
    </div>
  );
}

export default Card5;