import React from "react";

import "./styles.scss";

const ToolsCovered = () => {
  return (
    <div className="mlp-part-time-tools-covered">
      <div className="main-title">
        <h2 className="main-header">
                    What digital marketing tools will be covered?
        </h2>
        <img className="yellow-underline" src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21g4wjp.png" alt="Yellow Underline" />
      </div>
      <h4 className="sub-title">
                Here’s a list of various digital marketing tools that you’ll be introduced to in the 
                part-time digital marketing course. 
      </h4>
      <div className="tools-used-container">
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21fme2r.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21h9i6h.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21ftd63.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21gc85r.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21gdpp0.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21gfhbq.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21gt4rn.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21gveid.webp" style={{scale: "0.75"}} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21gxz9w.webp" style={{scale: "1.5"}} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21h04ev.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21h23jg.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21g03ev.png" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21h9i6h.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21hcu1b.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21hez48.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21hgvki.webp" style={{scale: "1.25"}} alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21hxshn.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21hzrv3.webp" alt="" loading="lazy" className="mlp-part-time-tool"/>
      </div>
    </div>
  );
};

export default ToolsCovered;