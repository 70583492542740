import React from "react";
import Slider from "react-slick";
import { trainersImages } from "./data";
import Card5 from "../../TestimonialCard/Card5-new/Part-time-card";
import "./styles.scss";
import { useLocation } from "@reach/router";

function CourseTracks() {
  const location = useLocation();

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          //   centerMode: true,
        },
      },
    ],
  };

  return (
    <div className="mlp-part-time-other-courses">
      <h2 className="other-courses-title">
        {" "}
        Check out the details for the other courses{" "}
      </h2>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28kqanp.png" className="yellow-underline-pt-track" loading="lazy" />

      <div className="courses-slider-section">
        <Slider {...settings}>
          {trainersImages.map((info, key) => (
            <Card5
              key={key}
              name={info.name}
              title={info.title ? info.title : ""}
              description={info.description}
              subTitle={info.subTitle ? info.subTitle : ""}
              link={info.link + location?.search}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default CourseTracks;
